import React from 'react'
import {Link, a} from 'react-router-dom';
import '../../assets/css/footer.css'; 
import { useRef  , useState } from 'react';
import emailjs from 'emailjs-com';



export default function Footer() {

    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");    
    
    const emailinput = React.useRef(null);

    // email field validation error hide code 
    const handleFocus = e => {
        e.target.classList.remove("error_line");

        let er3 = document.getElementById("eml_err");
        er3.classList.remove("show_error");    

    };

    // Form action code
    const sendEmail = (e) => {
        e.preventDefault();

        // Fields get value Code
        
        let email_fld= (emailinput.current.value);        
            
     
        // Fields validation Code
        
        if(email_fld == '')
        {
            let v3 = document.getElementById("email_fld");
            v3.className += " error_line";

            let er3 = document.getElementById("eml_err");
            er3.className += " show_error";
        }
        else
        {

            fetch('https://script.google.com/macros/s/AKfycbw5Zwfic7gSBKgFdlVqj5NmMgwxc1ktiPF17kQH2muUOOlBoOIEu0LkV75UX2P_sM6v/exec', {
                method: 'POST', 
                body: new FormData(form.current),
            
            }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
            .catch(err => console.log(err))
            
            
            emailjs.sendForm('service_8keqc5e', 'template_xc5jksd', e.target, 'SsPYHKCapw4h-xBn_')
            .then((result) => {

                console.log(result.text);
                // setStatusMessage("Your Message has been sent successfully");
                let bx = document.getElementById("subs_thnks");
                bx.className += " thnk_show";

                let frm_bx = document.getElementById("subs_form_bx");
                frm_bx.className += " form_hide";

            }, (error) => {

                console.log(error.text);
                setStatusMessage(`${error.text} happened`);

            });  
            e.target.reset()   

        }
    };    



  return (
    <>
        <div className='footer-main'>
        
        <div className='red-col' id='mob-subs'>
                <div>
                    <h2 className='rd-cl-ttl'>Subscribe to <br/> our newsletter</h2>
                    <form>
                        <input type="email" className='newslttr-email' placeholder="enter your email address"/>
                        <button className='newslttr-btn'>Subscribe</button>
                    </form>
                </div>
                <img className='footer_bg' src={require('../../assets/img/footer-bg.png')}></img>
        </div>

            <div className='blue-col'>
                <div className='row'>
                    <div className='col-12 blue-prt-1'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <a href="/"><img className='footer_logo' src={require('../../assets/img/footer-logo.png')}></img></a>
                                
                                <ul className='social-list'>
                                    <li>
                                        <a href="https://www.facebook.com/Capricornlogistics.in" className='social-circle'>
                                            <img className='fb-icon' src={require('../../assets/img/facebook.png')}></img>
                                            {/* <FontAwesomeIcon icon="fa-brands fa-facebook-square" /> */}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://in.linkedin.com/company/capricorn-logistics-pvt--ltd" className='social-circle'>
                                            <img className='twt-icon' src={require('../../assets/img/Linkedin.png')}></img>
                                            {/* <FontAwesomeIcon icon="fa-brands fa-linkedin" /> */}
                                        </a>
                                    </li>
                                    <li>
                                        {/* <a href="https://www.instagram.com/capricornlogistics/" className='social-circle'> */}
                                        <a href="https://www.instagram.com/capricornlogistics_/" className='social-circle'>

                                            <img className='insta-icon' src={require('../../assets/img/instagram.png')}></img>
                                            {/* <FontAwesomeIcon icon="fa-brands fa-instagram" /> */}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-8'>
                                <p className='footer-para'>Capricorn Logistics was founded to make a mark in India’s Clearing and Forwarding industry. We started our operations in all the major cities of India with the aim to offer the best logistics and supply chain solutions to our customers and have now expanded globally. </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 blue-prt-2'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h4 className='menu-ttl'>Services</h4>
                                <ul className='service-list'>
                                    <li><a href="/services/global-freight-forwarding-solutions" className='f-menu-link'>Global Freight Forwarding Solutions</a></li>
                                    <li><a href="/services/integrated-supply-chain-solutions/" className='f-menu-link'>Integrated Supply Chain Solutions</a></li>
                                    <li><a href="/services/custom-brokerage/" className='f-menu-link'>Custom Brokerage</a></li>
                                    <li><a href="/services/industrial-and-infactory-solutions/" className='f-menu-link'>Industrial & Infactory Solutions</a></li>
                                    <li><a href="/services/value-added-solutions" className='f-menu-link'>Value Added Solutions</a></li>
                                </ul>
                            </div>
                            <div className='col-lg-6'>
                                <h4 className='menu-ttl'>Links</h4>
                                <ul className='menu-list'>
                                    <li><a href="/about" className='f-menu-link'>About Us</a></li>
                                    {/* <li><a href="#" className='f-menu-link'>Portfolio</a></li> */}
                                    {/* <li><a href="/blog" className='f-menu-link'>News & Blog</a></li> */}
                                    <li><a href="/careers/life-at-capricorn" className='f-menu-link'>Careers</a></li>
                                    <li><a href="/contact" className='f-menu-link'>Contact</a></li>
                                    <li><a href='http://phpstack-608738-2577092.cloudwaysapps.com/wordpress/wp-content/uploads/2024/07/Corporate-PresentationNew.pdf' target="_blank" className='f-menu-link'>Company Presentation</a></li>
               

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 blue-prt-3'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <p className='cpy-txt'>Copyright © 2024. Capricorn Logistics. All rights reserved.</p>
                            </div>
                            <div className='col-lg-5'>
                                <a href="/terms-conditions" className='cpy-link'>Terms & Conditions</a>
                                <a href="/privacy-policy" className='cpy-link'>Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='red-col' id='desk-subs'>
                <div>
          
                    <h2 className='rd-cl-ttl'>Subscribe to <br/> our newsletter</h2>
                    <form ref={form} onSubmit={sendEmail} className='form_bx subscribe' id='subs_form_bx'>
                        {/* <input type="email" className='newslttr-email' placeholder="enter your email address"/> */}

                            <div className='fld_dv'>
                                <input type='email' className='input-fld newslttr-email' id='email_sub'  placeholder="enter your email address" name='email_sub' ref={emailinput} onFocus={handleFocus}></input>
                                <p className='error_msg' id='eml_err'>Please Enter Email id</p>
                            </div>

                            <button type='submit' value='Submit' className='newslttr-btn'>Subscribe</button>
                    </form>
                    <p className='thnk' id='subs_thnks'>Thank You For Subscribing!</p>
                </div>
                <img className='footer_bg' src={require('../../assets/img/footer-bg.png')}></img>
            </div>            
        </div>
        <div className='btmbr'>
            <div className='ps-bnr'>
                <p><a href='https://www.parashifttech.com/industries/logistics' target={'blank'}>Website designed by PARASHIFT</a></p>
            </div>        
        </div>
        
    </>
  )
}
